import React, {useEffect, useState} from "react";
import {Button, Form, Grid, Icon, Message, Select} from "semantic-ui-react";
import AuthDataProvider from "../../../Services/AuthDataProvider";
import ConsensoDataProvider from "../../../Services/ConsensoDataProvider";
import AdmiralDataProvider from "../../../Services/AdmiralDataProvider";
import OriginCDNMapperDataProvider from "../../../Services/OriginCDNMapperDataProvider";
import ProductCatalogDataProvider from "../../../Services/ProductCatalogDataProvider";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTable from "react-table-v6";
import ConsensoAssetsSubcomponent from "./ConsensoAssetsSubcomponent";

AdmiralDataProvider.init({baseURL: process.env.ADMIRAL_URL});
ConsensoDataProvider.init(process.env.CONSENSO_URL);
OriginCDNMapperDataProvider.init({baseURL: process.env.ORIGIN_CDN_MAPPER_URL});
ProductCatalogDataProvider.init(process.env.PRODUCT_CATALOG_URL);

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};

export default function ConsensoAssetsMasterDetailView(props) {
    const [brandOptions, setBrandOptions] = useState([]);
    const [readOnlyBrands, setReadOnlyBrands] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [mediaIdOptions, setMediaIdOptions] = useState([]);
    const [odtAiringIdOptions, setOdtAiringIdOptions] = useState([]);
    const [assetIdOptions, setAssetIdOptions] = useState([]);
    const [mediaIds, setMediaIds] = useState([]);
    const [odtAiringIds, setOdtAiringIds] = useState([]);
    const [assetIds, setAssetIds] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [assets, setAssets] = useState([]);
    const [searching, setSearching] = useState(false);
    const [brandProductsAndEntitlements, setBrandProductsAndEntitlements] = useState({});
    const [loadingBrands, setLoadingBrands] = useState(false);
    const [loadingProductsAndEntitlements, setLoadingProductsAndEntitlements] = useState(false);
    const [editableBrands, setEditableBrands] = useState([]);

    useEffect(function onPermissionsUpdate() {
        setLoadingBrands(true);
        const updatedEditableBrands = new Set();
        const updatedReadOnlyBrands = new Set();
        let userCanSeeAllBrands = false;
        let userCanEditAllBrands = false;
        for (const permission of props.userPermissions) {
            const userHasAspenPower = permission.urn === "urn:all:aspen-power";
            const serviceMatches = permission.service === props.service;
            const moduleMatches = permission.module === props.module;
            const userIsEditor = permission.role === "editor";

            if (userHasAspenPower) {
                userCanSeeAllBrands = true;
                userCanEditAllBrands = true;
                break;
            }

            if (serviceMatches && moduleMatches) {
                if (permission.scope === "all") {
                    userCanSeeAllBrands = true;
                    if (userIsEditor) {
                        userCanEditAllBrands = true;
                        updatedReadOnlyBrands.clear();
                        break;
                    }
                } else {
                    if (userIsEditor) {
                        updatedEditableBrands.add(permission.scope);
                    } else {
                        updatedReadOnlyBrands.add(permission.scope);
                    }
                }
            }
        }

        if (userCanSeeAllBrands) {
            console.log("ConsensoAssetsMasterDetailView.onPermissionsUpdate: user can see all brands? ", userCanSeeAllBrands, "edit all brands? ", userCanEditAllBrands);
            getAllBrands().then(brands => {
                console.log("ConsensoAssetsMasterDetailView.onPermissionsUpdate: brands? ", brands);
                for (const brand of brands) {
                    if (userCanEditAllBrands) {
                        updatedEditableBrands.add(brand);
                    } else if (!updatedEditableBrands.has(brand)) {
                        updatedReadOnlyBrands.add(brand);
                    }
                }

                const readOnlyBrandList = Array.from(updatedReadOnlyBrands);
                const editableBrandList = Array.from(updatedEditableBrands);

                readOnlyBrandList.sort();
                editableBrandList.sort();

                setReadOnlyBrands(readOnlyBrandList);
                setEditableBrands(editableBrandList);
            });
        } else {
            const readOnlyBrandList = Array.from(updatedReadOnlyBrands);
            const editableBrandList = Array.from(updatedEditableBrands);

            readOnlyBrandList.sort();
            editableBrandList.sort();

            setReadOnlyBrands(readOnlyBrandList);
            setEditableBrands(editableBrandList);
        }

        setLoadingBrands(false);
    }, [JSON.stringify(props.userPermissions)]);

    const getAllBrands = async () => {
        return await OriginCDNMapperDataProvider.getAll(props.user).then(response => {
            const scrubbedBrands = new Set();
            for (const mapping of response) {
                if (mapping.hasOwnProperty("brand")) {
                    const scrubbedBrand = mapping.brand.toLowerCase().replaceAll(/[^a-z\d]/g, "");
                    scrubbedBrands.add(scrubbedBrand);
                }
            }
            return Array.from(scrubbedBrands);
        }).catch(error => {
            console.error("ConsensoAuditMasterDetailView.initAppIdsAndBrandOptions", error);
            return [];
        });
    };

    useEffect(function initializeBrandOptions() {
        const allBrands = new Set();
        const updatedBrandOptions = [];
        for (const brand of editableBrands) {
            allBrands.add(brand);
        }

        for (const brand of readOnlyBrands) {
            allBrands.add(brand);
        }

        for (const brand of Array.from(allBrands)) {
            updatedBrandOptions.push({key: brand, text: brand, value: brand});
        }

        setBrandOptions(updatedBrandOptions);
    }, [JSON.stringify(editableBrands), JSON.stringify(readOnlyBrands)]);

    useEffect(function onAssetsUpdate() {
        setLoadingProductsAndEntitlements(true);
        new Promise(resolve => {
            const brandsToQuery = new Set();
            for (const asset of assets) {
                if (asset.hasOwnProperty("brand") && asset.brand && !brandProductsAndEntitlements.hasOwnProperty(asset.brand)) {
                    brandsToQuery.add(asset.brand);
                }
            }

            resolve(Array.from(brandsToQuery));
        }).then(async brandsToQuery => {
            const updatedBrandProductsAndEntitlements = Object.assign({}, brandProductsAndEntitlements);
            for (const brand of brandsToQuery) {
                const productsAndEntitlements = await getBrandProductsAndEntitlements(brand);
                updatedBrandProductsAndEntitlements[brand] = Object.assign({}, productsAndEntitlements);
            }
            console.log("ConsensoAssetsMasterDetailView.onAssetsUpdate: updating products to ", updatedBrandProductsAndEntitlements);
            setBrandProductsAndEntitlements(updatedBrandProductsAndEntitlements);
        }).finally(() => {
            setLoadingProductsAndEntitlements(false);
        });
    }, [JSON.stringify(assets)]);

    const updateBrandProductsAndEntitlements = (brand, product, entitlements=[]) => {
        const updatedBrand = brandProductsAndEntitlements.hasOwnProperty(brand) ? brandProductsAndEntitlements[brand] : {};
        const updatedEntitlements = updatedBrand.hasOwnProperty(product) ? updatedBrand[product].slice() : [];
        updatedBrand[product] = updatedEntitlements.concat(entitlements);
        const updatedBrandProductsAndEntitlements = Object.assign({}, brandProductsAndEntitlements, {[brand]: updatedBrand});
        console.log("ConsensoAssetsMasterDetailView.updateBrandProductsAndEntitlements: updated brands: ", updatedBrandProductsAndEntitlements);
        setBrandProductsAndEntitlements(updatedBrandProductsAndEntitlements);
    };

    const getBrandProductsAndEntitlements = async brand => {
        return await ProductCatalogDataProvider.getProductsForBrand(brand).then(response => {
            const products = {};
            if (response.error) {
                console.error(response.error);
            } else {
                for (const product of response.products) {
                    if (product.hasOwnProperty("universalProductIndicator") && product.universalProductIndicator) {
                        const entitlements = new Set();
                        if (product.hasOwnProperty("entitlements")) {
                            for (const entitlement of product.entitlements) {
                                if (entitlement.hasOwnProperty("entitlement") && entitlement.entitlement) {
                                    entitlements.add(entitlement.entitlement);
                                }
                            }
                        }

                        if (product.hasOwnProperty("storefrontProducts")) {
                            for (const storefront of Object.keys(product.storefrontProducts)) {
                                for (const storefrontProduct of product.storefrontProducts[storefront]) {
                                    if (storefrontProduct.hasOwnProperty("entitlements")) {
                                        for (const entitlement of storefrontProduct.entitlements) {
                                            if (entitlement.hasOwnProperty("entitlement") && entitlement.entitlement) {
                                                entitlements.add(entitlement.entitlement);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        products[product.universalProductIndicator] = Array.from(entitlements);
                    }
                }
            }
            return products;
        });
    };

    const performSearch = () => {
        setSearching(true);
        setSearchClicked(true);
        const brandsToSearch = new Set();
        if (selectedBrand.length > 0) {
            brandsToSearch.add(selectedBrand);
        } else {
            for (const brand of readOnlyBrands) {
                brandsToSearch.add(brand);
            }

            for (const brand of editableBrands) {
                brandsToSearch.add(brand);
            }
        }
        ConsensoDataProvider.getAssets(mediaIds, odtAiringIds, assetIds, Array.from(brandsToSearch), start, end).then(response => {
            console.log(response);
            setAssets(response.results);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setSearching(false);
        });
    };

    const clearFilters = () => {
        setSelectedBrand("");
        setMediaIds([]);
        setAssetIds([]);
        setOdtAiringIds([]);
        setStart(null);
        setEnd(null);
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={3}>
                <Form widths={16}>
                    <Form.Field>
                        <Button color="blue" onClick={performSearch} fluid>Search</Button>
                    </Form.Field>
                    <Form.Field>
                        <Form.Field>
                            <Button fluid color="black" onClick={clearFilters}>Clear Filters</Button>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field
                        label="Brand"
                        control={Select}
                        options={brandOptions}
                        value={selectedBrand}
                        search
                        clearable
                        allowAdditions
                        onChange={(event, {value}) => setSelectedBrand(value.toLowerCase().replaceAll(/[^a-z0-9]/g, ""))}
                        onAddItem={(event, {value}) => {
                            const scrubbedBrand = value.toLowerCase().replaceAll(/[^a-z0-9]/g, "");
                            setBrandOptions(brandOptions.slice().concat({key: scrubbedBrand, text: scrubbedBrand, value: scrubbedBrand}));
                        }}
                    />
                    <Form.Field
                        label="Asset IDs"
                        control={Select}
                        value={assetIds}
                        options={assetIdOptions}
                        allowAdditions
                        search
                        multiple
                        onChange={(event, {value}) => setAssetIds(value)}
                        onAddItem={(event, {value}) => setAssetIdOptions(assetIdOptions.concat({key: value, text: value, value}))}
                        clearable
                    />
                    <Form.Field
                        label="ODT Airing IDs"
                        control={Select}
                        value={odtAiringIds}
                        options={odtAiringIdOptions}
                        allowAdditions
                        search
                        multiple
                        onChange={(event, {value}) => setOdtAiringIds(value)}
                        onAddItem={(event, {value}) => setOdtAiringIdOptions(odtAiringIdOptions.concat({key: value, text: value, value}))}
                        clearable
                    />
                    <Form.Field
                        label="Media IDs"
                        control={Select}
                        value={mediaIds}
                        options={mediaIdOptions}
                        allowAdditions
                        search
                        multiple
                        onChange={(event, {value}) => setMediaIds(value)}
                        onAddItem={(event, {value}) => setMediaIdOptions(mediaIdOptions.concat({key: value, text: value, value}))}
                        clearable
                    />
                    <Form.Field>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <label>Start Time</label>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                id="start"
                                value={start}
                                onChange={setStart}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                    <Form.Field width={16}>
                        <label>End Time</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                id="end"
                                value={end}
                                onChange={setEnd}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={13} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "auto"}}>
                <Grid.Row>
                    {
                        loadingBrands ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>Loading Brands...</Message.Content>
                            </Message> :
                        searching ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>Loading Assets...</Message.Content>
                            </Message> :
                        loadingProductsAndEntitlements ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>Loading Products and Entitlements...</Message.Content>
                            </Message> :
                        !searchClicked ?
                            <Message icon color="yellow">
                                <Icon name="info" />
                                <Message.Content>Enter your search criteria and click search to view assets.</Message.Content>
                            </Message> :
                            <ReactTable
                                data={assets}
                                columns={
                                    [
                                        {Header: "Media ID", accessor: "mediaId"},
                                        {Header: "Brand", accessor: "brand"},
                                        {Header: "Type", accessor: "type"},
                                        {
                                            Header: "Created At",
                                            accessor: "createdAt",
                                            Cell: row => <span>{new Date(row.original.createdAt * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>
                                        },
                                        {
                                            Header: "Last Updated",
                                            accessor: "lastUpdated",
                                            Cell: (row) => <span>{new Date(row.original.lastUpdated * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>
                                        }
                                    ]
                                }
                                filterable={true}
                                defaultSorted={[
                                    {id: "lastUpdated", desc: true}
                                ]}
                                defaultPageSize={100}
                                SubComponent={
                                    row =>
                                        <ConsensoAssetsSubcomponent
                                            brand={row.original.brand}
                                            brandProductsAndEntitlements={brandProductsAndEntitlements}
                                            updateBrandProductsAndEntitlements={updateBrandProductsAndEntitlements}
                                            asset={row.original}
                                            flights={row.original.flights}
                                            assetIds={row.original.assetIds}
                                            ConsensoDataProvider={ConsensoDataProvider}
                                            readOnlyBrands={readOnlyBrands}
                                            service={props.service}
                                            module={props.module}
                                            userPermissions={props.userPermissions}
                                            user={props.user}
                                            permissions={props.permissions}
                                        />
                                }
                            />
                    }
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
