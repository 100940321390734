import React, {useState, useEffect} from "react";
import {Button, Container, Grid, Header, Icon} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import AdmiralDataProvider from "../../../Services/AdmiralDataProvider";
import CampaignManagerDetailView from "./CampaignManagerDetailView";
import ContingentButton from "../../ContingentButton";

export default function CampaignManager(props) {
    const [rules, setRules] = useState([]);
    const [newClicked, setNewClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedRule, setSelectedRule] = useState({});
    const [selectedRuleId, setSelectedRuleId] = useState("");
    const [ruleSearchAttributes, setRuleSearchAttributes] = useState({});
    const [ruleReplaceAttributes, setRuleReplaceAttributes] = useState({});
    const [ingestBrands, setIngestBrands] = useState({});
    const [savingRule, setSavingRule] = useState(false);
    const [savingRuleMessage, setSavingRuleMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");

    AdmiralDataProvider.init({baseURL: process.env.ADMIRAL_URL});

    useEffect(function initRules() {
        refreshRules();
    }, []);

    useEffect(function initMetadata() {
        refreshMetadata();
    }, []);

    useEffect(function updateSelectedRule() {
        setSelectedRule({});
        if (selectedRuleId) {
            for (const rule of rules) {
                if (rule._id === selectedRuleId) {
                    setSelectedRule(rule);
                    break;
                }
            }
        }
    }, [JSON.stringify(rules), selectedRuleId]);

    const onClickRefresh = () => {
        refreshMetadata();
        refreshRules();
    };

    const onClickNew = () => {
        setNewClicked(true);
        setSelectedRule({});
        setSavingRuleMessage("");
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
    };

    const onClickSave = async (role, enabled, description, search, replace, priority) => {
        setSavingRule(true);
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        setSavingRuleMessage("Saving rule...");
        await AdmiralDataProvider.saveRule(role, enabled, description, search, replace, priority).then(response => {
            console.log("(CampaignManager.onClickSave) response: ", response);
            if (!response.hasOwnProperty("error")) {
                setNewClicked(false);
                setSaveSuccessMessage("This rule has been saved.");
            } else {
                setSaveErrorMessage("There was an error saving this rule.");
                console.error("CampaignManager.onClickSave: ", response.error);
            }
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error saving this rule.");
        }).then(refreshRules).catch(error => {
            console.error(error);
        }).finally(() => {
            setSavingRule(false);
            setSavingRuleMessage("");
        });
    };

    const refreshRules = () => {
        setLoading(true);
        AdmiralDataProvider.getRules().then(response => {
            console.log(response);
            setRules(response.results);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const refreshMetadata = () => {
        AdmiralDataProvider.getMetadata().then(response => {
            if (response.hasOwnProperty("metadata")) {
                if (response.metadata.hasOwnProperty("rule_search_attrs")) {
                    setRuleSearchAttributes(response.metadata.rule_search_attrs);
                } else {
                    console.warn("(CampaignManager.refreshMetadata) had no rule search attributes.", response);
                    setRuleSearchAttributes({});
                }
                if (response.metadata.hasOwnProperty("rule_replace_attrs")) {
                    setRuleReplaceAttributes(response.metadata.rule_replace_attrs);
                } else {
                    console.warn("(CampaignManager.refreshMetadata) had no rule replace attributes.", response);
                    setRuleReplaceAttributes({});
                }
                if (response.metadata.hasOwnProperty("ingest_brands")) {
                    setIngestBrands(response.metadata.ingest_brands);
                } else {
                    console.warn("(CampaignManager.refreshMetadata) had no ingest brand attributes.", response);
                    setIngestBrands({});
                }
            } else {
                console.error("(CampaignManager.refreshMetadata) had no metadata at all.", response);
            }
        }).catch(error => {
            props.toast("Error", "Error getting metadata", "error");
            console.error(error);
        });
    }

    const formatRow = (key, value) => <span><b>{key}</b>: {value}<br /></span>;

    const deleteClicked = (enabled, search, replace) => {
        setSavingRule(true);
        setSavingRuleMessage("Deleting rule...");
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        AdmiralDataProvider.deleteRule(enabled, search, replace).then(response => {
            console.log("(CampaignManager.deleteClicked) deletion response: ", response);
            setSelectedRule({});
            setSaveSuccessMessage("This rule was successfully deleted.");
        }).then(refreshRules).catch(error => {
            console.error("(CampaignManager.deleteClicked) error while deleting rule: ", error);
            setSaveErrorMessage("There was an error while deleting this rule.");
        }).finally(() => {
            setSavingRule(false);
            setSavingRuleMessage("");
        });
    };

    return (
        <Grid className="masterDetailContainer">
            <Grid.Row>
                <Grid.Column width={9} className="masterContainer" style={{maxHeight: "89vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Column floated="right" width={16}>
                                <Container fluid textAlign='right' className='actionBarContainer'>
                                    <Button.Group>
                                        <Button onClick={onClickRefresh}><Icon name="refresh"/>Refresh</Button>
                                        <Button.Or text="" />
                                        <ContingentButton
                                            onClick={onClickNew}
                                            secondary
                                            service={props.service}
                                            module={props.module}
                                            scope="any"
                                            allPermissions={props.permissions}
                                            user={props.user}
                                        ><Icon name="plus"/>New Rule</ContingentButton>
                                    </Button.Group>
                                </Container>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>
                    <ReactTable
                        data={rules}
                        columns={[
                            {accessor: "_id", show: false},
                            {accessor: "description", show: false},
                            {Header: "Role", accessor: "role", width: 100},
                            {
                                Header: "Search Criteria",
                                accessor: "search",
                                Cell: row => {
                                    const rows = Object.keys(row.value).map(key => {
                                        const field = ruleSearchAttributes.hasOwnProperty(key) ? ruleSearchAttributes[key] : key;
                                        const value = key === "brand" && ingestBrands.hasOwnProperty(row.value[key]) ? ingestBrands[row.value[key]] : row.value[key];
                                        return formatRow(field, value);
                                    });
                                    return <Container><pre>{rows}</pre></Container>
                                },
                                filterMethod: (filter, rows) => JSON.stringify(rows[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                            },
                            {
                                Header: "Replacement Criteria",
                                accessor: "replace",
                                Cell: row => {
                                    const rows = Object.keys(row.value).map(key => {
                                        const field = ruleReplaceAttributes.hasOwnProperty(key) ? ruleReplaceAttributes[key] : key;
                                        const value = row.value[key];
                                        return formatRow(field, value);
                                    });
                                    return <Container><pre>{rows}</pre></Container>
                                },
                                filterMethod: (filter, rows) => JSON.stringify(rows[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                            },
                            {Header: "Priority", accessor: "priority", width: 65},
                            {Header: "Enabled", accessor: "enabled", Cell: row => <span>{row.value.toString()}</span>, width: 65}
                        ]}
                        loading={loading}
                        filterable
                        defaultFilterMethod={(filter, rows) => {
                            if(rows[filter.id] !== null && rows[filter.id] !== undefined)
                                return rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                        }}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick(event, handleOriginal) {
                                    setSelectedRuleId(rowInfo.original._id);
                                    setNewClicked(false);
                                    setSavingRuleMessage("");
                                    setSaveSuccessMessage("");
                                    setSaveErrorMessage("");
                                },
                                style: {
                                    background: rowInfo && rowInfo.row && selectedRule._id === rowInfo.row._id ? 'rgba(65, 83, 175, .5)' : '',
                                }
                            };
                        }}
                        defaultSorted={[{id: "role", asc: true }, {id: "priority", asc: false}]}
                    />
                </Grid.Column>
                <Grid.Column width={7} className="detailsContainer" style={{maxHeight: "89vh", overflowY: "auto", overflowX: "hidden"}}>
                    <CampaignManagerDetailView
                        selectedRule={selectedRule}
                        AdmiralDataProvider={AdmiralDataProvider}
                        newClicked={newClicked}
                        onClickSave={onClickSave}
                        onClickDelete={deleteClicked}
                        user={props.user}
                        ruleSearchAttributes={ruleSearchAttributes}
                        ruleReplaceAttributes={ruleReplaceAttributes}
                        ingestBrands={ingestBrands}
                        savingRule={savingRule}
                        savingRuleMessage={savingRuleMessage}
                        saveErrorMessage={saveErrorMessage}
                        saveSuccessMessage={saveSuccessMessage}
                        service={props.service}
                        module={props.module}
                        permissions={props.permissions}
                        userPermissions={props.userPermissions}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
