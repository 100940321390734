import React, {useState, useEffect} from "react";
import {Grid, Tab, Segment, Button, Message, Icon, Popup} from "semantic-ui-react";
import ConsensoFlightTable from "./ConsensoFlightTable";
import _ from "lodash";
import ContingentButton from "../../ContingentButton";

export default function ConsensoAssetsSubcomponent(props) {
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [initialFlights, setInitialFlights] = useState([]);
    const [currentFlights, setCurrentFlights] = useState([]);
    const [assetIdsFound, setAssetIdsFound] = useState(false);
    const [flightsFound, setFlightsFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [updating, setUpdating] = useState(false);
    const [productOptions, setProductOptions] = useState([]);
    const [isBrandReadOnly, setIsBrandReadOnly] = useState(true);

    useEffect(function updateIsBrandReadOnly() {
        const updatedReadOnly = props.readOnlyBrands.includes(props.brand);
        console.log("ConsensoAssetsSubcomponent.updateIsBrandReadOnly: ", updatedReadOnly, "brand? ", props.brand, "read-only brands? ", props.readOnlyBrands, "flights? ", props.flights);
        setIsBrandReadOnly(updatedReadOnly);
    }, [props.brand, JSON.stringify(props.readOnlyBrands)]);

    useEffect(function updateProductOptionsOnPropsUpdate() {
        console.log("ConsensoAssetsSubcomponent.updateProductOptionsOnPropsUpdate products: ", Object.keys(props.brandProductsAndEntitlements[props.brand]));
        const updatedProductOptions = [];
        for (const product of Object.keys(props.brandProductsAndEntitlements[props.brand])) {
            updatedProductOptions.push({key: product, text: product, value: product});
        }

        setProductOptions(updatedProductOptions);
    }, [JSON.stringify(props.brandProductsAndEntitlements), props.brand]);

    useEffect(function updateEntitlementOptions() {
        const currentSelectedProducts = new Set();
        for (const flight of currentFlights) {
            for (const product of flight.products) {
                currentSelectedProducts.add(product);
            }
        }

    }, [JSON.stringify(currentFlights)]);

    useEffect(function updateAssetIdsFound() {
        setAssetIdsFound(Array.isArray(props.assetIds) && props.assetIds.length > 0);
    }, [props.assetIds]);

    useEffect(function updateCurrentFlights() {
        setFlightsFound(Array.isArray(props.flights) && props.flights.length > 0);
        const updatedCurrentFlights = [];
        const updatedInitialFlights = [];
        if (Array.isArray(props.flights)) {
            for (const flight of props.flights) {
                console.log("flight: ", flight);
                updatedCurrentFlights.push(Object.assign({}, flight));
                updatedInitialFlights.push(Object.assign({}, flight));
            }
        }

        console.log("updated current flights: ", updatedCurrentFlights);
        setCurrentFlights(updatedCurrentFlights);
        setInitialFlights(updatedInitialFlights);
    }, [props.flights]);

    useEffect(function updateUnsavedChanges() {
        let updatedUnsavedChanges = false;
        if (Array.isArray(initialFlights) && initialFlights.length === currentFlights.length) {
            console.log(currentFlights, initialFlights);
            for (let i = 0; i < initialFlights.length; i++) {
                const isAuthnUpdated = initialFlights[i].authn !== currentFlights[i].authn;
                const areProductsUpdated = _.difference(initialFlights[i].products, currentFlights[i].products).length > 0 || _.difference(currentFlights[i].products, initialFlights[i].products).length > 0;
                const areEntitlementsUpdated = _.difference(initialFlights[i].entitlements, currentFlights[i].entitlements).length > 0 || _.difference(currentFlights[i].entitlements, initialFlights[i].entitlements).length > 0;
                const initialFlightPermissions = Object.assign({
                    countries: [],
                    allowed: false
                }, initialFlights[i].permissions);
                const currentFlightPermissions = Object.assign({
                    countries: [],
                    allowed: false
                }, currentFlights[i].permissions);

                if (Array.isArray(initialFlightPermissions.countries)) {
                    const lowercaseCountries = [];
                    for (const country of initialFlightPermissions.countries.slice()) {
                        lowercaseCountries.push(country.toLowerCase());
                    }
                    initialFlightPermissions.countries = lowercaseCountries.slice();
                }

                if (Array.isArray(currentFlightPermissions.countries)) {
                    const lowercaseCountries = [];

                    for (const country of currentFlightPermissions.countries.slice()) {
                        lowercaseCountries.push(country.toLowerCase());
                    }
                    currentFlightPermissions.countries = lowercaseCountries.slice();
                }

                const areSelectedCountriesUpdated = [initialFlightPermissions.countries, currentFlightPermissions.countries].includes("all") ? initialFlightPermissions.countries !== currentFlightPermissions.countries :
                    _.difference(initialFlightPermissions.countries, currentFlightPermissions.countries).length > 0 || _.difference(currentFlightPermissions.countries, initialFlightPermissions.countries).length > 0;
                const isSelectedCountriesAreAllowedUpdated = initialFlightPermissions.allowed !== currentFlightPermissions.allowed;
                console.log("ConsensoFlightRow.updateUnsavedChanges: authn updated? ", isAuthnUpdated, initialFlights[i].authn, currentFlights[i].authn);
                console.log("ConsensoFlightRow.updateUnsavedChanges: products updated? ", areProductsUpdated);
                console.log("ConsensoFlightRow.updateUnsavedChanges: entitlements updated? ", areEntitlementsUpdated);
                console.log("ConsensoFlightRow.updateUnsavedChanges: selected countries updated? ", areSelectedCountriesUpdated, initialFlightPermissions.countries, currentFlightPermissions.countries);
                console.log("ConsensoFlightRow.updateUnsavedChanges: selected countries are allowed updated? ", isSelectedCountriesAreAllowedUpdated);
                if (isAuthnUpdated || areProductsUpdated || areEntitlementsUpdated || areSelectedCountriesUpdated || isSelectedCountriesAreAllowedUpdated) {
                    updatedUnsavedChanges = true;
                    break;
                }
            }
        }

        console.log("updating unsaved changes value to ", updatedUnsavedChanges);
        setUnsavedChanges(updatedUnsavedChanges);

        setErrorMessage("");
        setSuccessMessage("");
    }, [JSON.stringify(currentFlights), JSON.stringify(props.flights)]);

    const updateFlight = (flight, index) => {
        const updatedFlights = currentFlights.slice();
        updatedFlights[index] = Object.assign({}, flight);
        console.log(`ConsensoAssetsSubcomponent.updateFlight: updating index ${index} to `, flight);
        setCurrentFlights(updatedFlights);
    };

    const updateAsset = () => {
        setUpdating(true);
        const assetToSend = {...props.asset};
        assetToSend.flights = currentFlights.slice();

        props.ConsensoDataProvider.updateAsset(assetToSend).then(response => {
            if (response.hasOwnProperty("error")) {
                console.log(response.error);
                setErrorMessage(response.error);
                return;
            }

            const updatedInitialFlights = [];
            for (const flight of currentFlights) {
                updatedInitialFlights.push(Object.assign({}, flight));
            }
            setInitialFlights(updatedInitialFlights);
            setSuccessMessage(response.message);
            setUnsavedChanges(false);
        }).catch(error => {
            setErrorMessage(error.toString());
        }).finally(() => {
            setUpdating(false);
        });
    };

    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    fluid
                    styled
                    panes={[
                        {
                            menuItem: "Asset IDs",
                            render: () =>
                                <Segment>
                                    {
                                        !assetIdsFound ?
                                            <Message icon color="yellow">
                                                <Icon name="info"/>
                                                <Message.Content>This asset has no associated asset IDs.</Message.Content>
                                            </Message> :
                                            <pre>{Array.isArray(props.assetIds) ? props.assetIds.join("\n") : ""}</pre>}
                                </Segment>
                        },
                        {
                            menuItem: "Flight Windows",
                            render: () =>
                                !flightsFound ?
                                    <Segment>
                                        <Message icon color="yellow">
                                            <Icon name="info" />
                                            <Message.Content>This asset has no associated flights.</Message.Content>
                                        </Message>
                                    </Segment> :
                                    <Grid className="masterContainer">
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                {
                                                    currentFlights.map((currentFlight, index) =>
                                                    {
                                                        console.log("ConsensoAssetsSubcomponent.render: current flight:", currentFlight);
                                                        return (
                                                            <ConsensoFlightTable
                                                                initialFlight={initialFlights[index]}
                                                                currentFlight={currentFlight}
                                                                updateFlight={updateFlight}
                                                                productOptions={productOptions}
                                                                setProductOptions={setProductOptions}
                                                                brandProductsAndEntitlements={props.brandProductsAndEntitlements[props.brand]}
                                                                index={index}
                                                                key={`flight-${currentFlight.odtAiringId}-${index}`}
                                                                readOnly={isBrandReadOnly}
                                                            />
                                                        );
                                                    })
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16} floated="right">
                                                {
                                                    errorMessage.length > 0 ?
                                                        <Message icon color="red" fluid>
                                                            <Icon name="warning" />
                                                            <Message.Content>
                                                                {errorMessage}
                                                            </Message.Content>
                                                        </Message> :
                                                    successMessage.length > 0 ?
                                                        <Message icon color="green" fluid>
                                                            <Icon name="check" />
                                                            <Message.Content>
                                                                {successMessage}
                                                            </Message.Content>
                                                        </Message> : ""
                                                }
                                                {
                                                    isBrandReadOnly ? "" :
                                                        <Popup
                                                            open={unsavedChanges}
                                                            content="You have made unsaved changes."
                                                            position="left center"
                                                            trigger={
                                                                <ContingentButton
                                                                    floated="right"
                                                                    primary
                                                                    content={<span>{updating ? <Icon name="spinner" loading/> : ""}Update Flights</span>}
                                                                    onClick={() => updateAsset()}
                                                                    allPermissions={props.permissions}
                                                                    user={props.user}
                                                                    service={props.service}
                                                                    module={props.module}
                                                                    scope={props.brand}
                                                                />
                                                            }
                                                        />
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row />
                                    </Grid>
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
