import React, {useState, useEffect} from "react";
import {Button, Checkbox, Divider, Form, Icon, Input, Message, Popup, Select, Table, TextArea} from "semantic-ui-react";
import AuthDataProvider from "../../../Services/AuthDataProvider";
import UserDataProvider from "../../../Services/UserDataProvider";
import ContingentButton from "../../ContingentButton";
import PropTypes from "prop-types";


export default function CampaignManagerDetailView(props) {
    const [searchCriteriaOptions, setSearchCriteriaOptions] = useState([]);
    const [replaceCriteriaOptions, setReplaceCriteriaOptions] = useState([]);
    const [brandOptions, setBrandOptions] = useState([]);
    const [remainingSearchCriteriaOptions, setRemainingSearchCriteriaOptions] = useState(searchCriteriaOptions);
    const [remainingReplacementCriteriaOptions, setRemainingReplacementCriteriaOptions] = useState(replaceCriteriaOptions);
    const [selectedSearchCriteriaOption, setSelectedSearchCriteriaOption] = useState("");
    const [selectedReplacementCriteriaOption, setSelectedReplacementCriteriaOption] = useState("");
    const [search, setSearch] = useState({});
    const [replace, setReplace] = useState({});
    const [selectedRole, setSelectedRole] = useState("");
    const [roleOptions, setRoleOptions] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(100);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [userCanEdit, setUserCanEdit] = useState(false);

    UserDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL});

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = false;
        for (const permission of props.userPermissions) {
            console.log(permission);
            const userHasAspenPower = permission.service === "all" && permission.module === "all" && permission.scope === "all" && permission.role === "aspen-power";
            const userHasRelevantPermission = permission.service === props.service && permission.module === props.module && permission.role === "editor";
            updatedUserCanEdit ||= userHasAspenPower || userHasRelevantPermission;
            if (updatedUserCanEdit) {
                console.log("CampaignManagerDetailView.updateUserCanEdit: aspen power? ", userHasAspenPower, "user has relevant permission? ", userHasRelevantPermission, permission);
                break;
            }
        }
        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.user), JSON.stringify(props.permissions)]);

    useEffect(function updateSearchOptions() {
        const updatedSearchCriteria = [];
        for (const key of Object.keys(props.ruleSearchAttributes)) {
            updatedSearchCriteria.push({key, text: props.ruleSearchAttributes[key], value: key});
        }
        console.log("search criteria options", updatedSearchCriteria);
        setSearchCriteriaOptions(updatedSearchCriteria);
    }, [JSON.stringify(props.ruleSearchAttributes)]);

    useEffect(function updateReplaceOptions() {
        const updatedReplaceCriteria = [];
        for (const key of Object.keys(props.ruleReplaceAttributes)) {
            updatedReplaceCriteria.push({key, text: props.ruleReplaceAttributes[key], value: key});
        }
        console.log("replace criteria options", updatedReplaceCriteria);
        setReplaceCriteriaOptions(updatedReplaceCriteria);
    }, [JSON.stringify(props.ruleReplaceAttributes)]);

    useEffect(function updateBrandOptions() {
        const updatedBrandOptions = [];
        if (props.ingestBrands) {
            for (const key of Object.keys(props.ingestBrands)) {
                updatedBrandOptions.push({key, text: props.ingestBrands[key], value: key});
            }
        }
        console.log("brand options", updatedBrandOptions);
        setBrandOptions(updatedBrandOptions);
    }, [JSON.stringify(props.ingestBrands)]);

    useEffect(function updateStateOnRuleChange() {
        if (props.selectedRule && Object.keys(props.selectedRule).length > 0) {
            const rule = Object.assign({}, props.selectedRule);
            setSearch(Object.assign({}, rule.search));
            setSelectedRole(rule.role);
            setEnabled(rule.enabled);
            setDescription(rule.description);
            setReplace(Object.assign({}, rule.replace));
            setPriority(rule.priority);
            setRemainingSearchCriteriaOptions(searchCriteriaOptions.filter(option => !Object.keys(rule.search).includes(option.value)));
            setRemainingReplacementCriteriaOptions(replaceCriteriaOptions.filter(option => !Object.keys(rule.replace).includes(option.value)));
        } else {
            setSearch({});
            setSelectedRole("");
            setEnabled(false);
            setDescription("");
            setReplace({});
            setPriority(100);
        }
    }, [JSON.stringify(props.selectedRule)]);

    useEffect(function updateUnusedSearchCriteria() {
        setRemainingSearchCriteriaOptions(searchCriteriaOptions.filter(option => !Object.keys(search).includes(option.value)));
    }, [Object.keys(search).length, JSON.stringify(searchCriteriaOptions)]);

    useEffect(function updateUnusedReplacementCriteria() {
        setRemainingReplacementCriteriaOptions(replaceCriteriaOptions.filter(option => !Object.keys(replace).includes(option.value)));
    }, [Object.keys(replace).length, JSON.stringify(replaceCriteriaOptions)]);

    useEffect(function updateUnsavedChanges() {
        setUnsavedChanges(false);
        if (props.newClicked) {
            setUnsavedChanges(Object.keys(search).length > 0 || Object.keys(replace).length > 0 || selectedRole !== "" ||
            description !== "" || enabled);
        } else if (Object.keys(props.selectedRule).length > 0) {
            const enableToggled = props.selectedRule.enabled !== enabled;
            const isDescriptionUpdated = props.selectedRule.description !== description;
            const isRoleUpdated = props.selectedRule.role !== selectedRole;
            const isPriorityUpdated = props.selectedRule.priority !== priority;
            let isSearchUpdated = typeof props.selectedRule.search !== "object" || props.selectedRule.search == null || Object.keys(props.selectedRule.search).length !== Object.keys(search).length;
            if (!isSearchUpdated) {
                for (const key of Object.keys(search)) {
                    isSearchUpdated = isSearchUpdated && search[key] !== props.selectedRule.search[key];
                    if (isSearchUpdated) break;
                }
            }
            if (!isSearchUpdated) {
                for (const key of Object.keys(props.selectedRule.search)) {
                    isSearchUpdated = search[key] !== props.selectedRule.search[key];
                    if (isSearchUpdated) break;
                }
            }

            let isReplaceUpdated = typeof props.selectedRule.replace !== "object" || props.selectedRule.replace == null || Object.keys(props.selectedRule.replace).length !== Object.keys(replace).length;
            if (!isReplaceUpdated) {
                for (const key of Object.keys(replace)) {
                    isReplaceUpdated = replace[key] !== props.selectedRule.replace[key];
                    if (isReplaceUpdated) break;
                }
            }
            if (!isReplaceUpdated) {
                for (const key of Object.keys(props.selectedRule.replace)) {
                    isReplaceUpdated = replace[key] !== props.selectedRule.replace[key];
                    if (isReplaceUpdated) break;
                }
            }

            setUnsavedChanges(enableToggled || isSearchUpdated || isReplaceUpdated || isDescriptionUpdated || isRoleUpdated || isPriorityUpdated);
        }
    }, [JSON.stringify(props.selectedRule), JSON.stringify(search), JSON.stringify(replace), selectedRole, enabled, description, priority]);

    useEffect(function initRoleOptions() {
        AuthDataProvider.getAllPermissions().then(permissions => {
            console.log("CampaignManagerDetailView.initRoleOptions permissions: ", permissions);
            const updatedRoleOptions = [];
            for (const permission of props.permissions) {
                if (permission.service === props.service && permission.module === props.module && permission.scope !== "all") {
                    updatedRoleOptions.push({key: permission.scope, text: permission.scope, value: permission.scope});
                }
            }

            console.log("CampaignManagerDetailView.initRoleOptions role options: ", updatedRoleOptions);
            setRoleOptions(updatedRoleOptions);
        });
    }, [JSON.stringify(props.permissions), props.service, props.module]);

    const saveClicked = async () => {
        let role =`urn:acm:${selectedRole}`;
        await props.onClickSave(role, enabled, description, search, replace, priority);
    };

    const deleteClicked = () => {
        props.onClickDelete(enabled, search, replace);
    }

    return (
            props.newClicked || Object.keys(props.selectedRule).length > 0 ?
                <Form>
                    {
                        props.savingRule ?
                            <Message icon color="blue"><Icon name="spinner" loading/>{props.savingRuleMessage}</Message> :
                            props.saveSuccessMessage.length > 0 ?
                                <Message icon color="green"><Icon name="check"/>{props.saveSuccessMessage}</Message> :
                                props.saveErrorMessage.length > 0 ?
                                    <Message icon color="red"><Icon name="x"/>{props.saveErrorMessage}</Message> : ""
                    }
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Popup
                            open={unsavedChanges}
                            content="The selected rule has unsaved changes."
                            color="red"
                            trigger={
                                <ContingentButton
                                    primary
                                    onClick={saveClicked}
                                    floated="left"
                                    service={props.service}
                                    module={props.module}
                                    scope="any"
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >{props.newClicked ? "Create Rule" : "Save Rule"}</ContingentButton>
                            }
                            position="right center"
                            />
                        </Form.Field>
                        <Form.Field>
                            <ContingentButton
                                color="red"
                                floated="right"
                                onClick={deleteClicked}
                                service={props.service}
                                module={props.module}
                                scope="any"
                                user={props.user}
                                allPermissions={props.permissions}
                            >Delete Rule</ContingentButton>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field />
                    <Form.Group widths="equal">
                        <Form.Field
                            control={Select}
                            label="Role"
                            options={roleOptions}
                            value={selectedRole}
                            onChange={(event, {value}) => setSelectedRole(value)}
                            fluid
                            readOnly={!userCanEdit}
                        />
                        <Form.Field
                            control={Input}
                            label="Priority"
                            value={priority}
                            onChange={(event, {value}) => setPriority(value)}
                            type="number"
                            readOnly={!userCanEdit}
                        />
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Checkbox
                                toggle
                                label="Enabled"
                                checked={enabled}
                                onClick={(event, {checked}) => setEnabled(checked)}
                                fluid
                                readOnly={!userCanEdit}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Field
                        control={TextArea}
                        label="Description"
                        value={description}
                        onChange={(event, {value}) => setDescription(value)}
                        readOnly={!userCanEdit}
                    />
                    <Divider horizontal>Search Criteria</Divider>
                    {
                        !userCanEdit ? "" :
                        remainingSearchCriteriaOptions.length > 0 ?
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="&nbsp;"
                                    control={Select}
                                    options={remainingSearchCriteriaOptions}
                                    onChange={(event, {value}) => setSelectedSearchCriteriaOption(value)}
                                    fluid
                                />
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button
                                        fluid
                                        onClick={() => setSearch(Object.assign({}, search, {[selectedSearchCriteriaOption]: ""}))}
                                    >Add Search Criterion</Button>
                                </Form.Field>
                            </Form.Group> : <Message icon color="yellow"><Icon name="info" />There are no further search criteria options to add.</Message>
                    }
                    {
                        Object.keys(search).length > 0 ?
                            <Table columns={2}>
                                {
                                    Object.keys(search).map(key =>
                                        <Table.Row key={`search-criteria-row-${key}`}>
                                            <Table.Cell>
                                                <Button
                                                    fluid
                                                    secondary
                                                    onClick={() => {
                                                        const {[key]: undefined, ...updatedSearch} = search;
                                                        setSearch(Object.assign({}, updatedSearch));
                                                    }}
                                                    disabled={!userCanEdit}
                                                >
                                                    <Icon name="minus"/>{searchCriteriaOptions.find(option => option.value === key).text}
                                                </Button>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form>
                                                    {
                                                        key === "brand" ?
                                                            <Form.Field
                                                                control={Select}
                                                                value={search[key]}
                                                                options={brandOptions}
                                                                onChange={(event, {value}) => setSearch(Object.assign({}, search, {[key]: value}))}
                                                            /> :
                                                            <Form.Field
                                                                control={Input}
                                                                value={search[key]}
                                                                onChange={(event, {value}) => setSearch(Object.assign({}, search, {[key]: value}))}
                                                            />
                                                    }
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table> : <Message icon color="yellow"><Icon name="info"/>Click a button to add to the search criteria for this rule.</Message>
                    }
                    <Divider horizontal>Replacement Criteria</Divider>
                    {
                        !userCanEdit ? "" :
                        remainingReplacementCriteriaOptions.length > 0 ?
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="&nbsp;"
                                    control={Select}
                                    options={remainingReplacementCriteriaOptions}
                                    fluid
                                    onChange={(event, {value}) => setSelectedReplacementCriteriaOption(value)}
                                />
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button
                                        fluid
                                        onClick={() => setReplace(Object.assign({}, replace, {[selectedReplacementCriteriaOption]: ""}))}
                                        disabled={!userCanEdit}
                                    >Add Replacement Criterion</Button>
                                </Form.Field>
                            </Form.Group> : <Message icon color="yellow"><Icon name="info" />There are no further replacement criteria options to add.</Message>
                    }

                    {
                        Object.keys(replace).length > 0 ?
                            <Table columns={2}>
                                {
                                    Object.keys(replace).map(key =>
                                        <Table.Row key={`replacement-rule-row-${key}`}>
                                            <Table.Cell>
                                                <Button
                                                    fluid
                                                    secondary
                                                    onClick={() => {
                                                        const {[key]: undefined, ...updatedReplace} = replace;
                                                        setReplace(Object.assign({}, updatedReplace));
                                                    }}
                                                    disabled={!userCanEdit}
                                                >
                                                    <Icon name="minus"/>{replaceCriteriaOptions.find(option => option.value === key).text}
                                                </Button>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form>
                                                    <Form.Field
                                                        control={Input}
                                                        value={replace[key]}
                                                        onChange={(event, {value}) => setReplace(Object.assign({}, replace, {[key]: value}))}
                                                    />
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table> : <Message icon color="yellow"><Icon name="info" />Click a button to add to the replacement for this rule.</Message>
                    }
                </Form> :
                userCanEdit ?
                    <Message icon color="yellow">Please select a rule or click New to continue.</Message> :
                    <Message icon color="yellow">Please select a rule to continue.</Message>
    );
};

CampaignManagerDetailView.propTypes = {
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired
}
